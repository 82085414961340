<template>
  <div class="app-calendar border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <!-- <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div> -->

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->

      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      ></div>
      <!-- <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      /> -->
      <b-modal
        centered
        @ok="add_record"
        :title="lang('t_addEvent')"
        v-model="isEventHandlerSidebarActive"
        max-width="540"
      >
        <!-- Event Title -->

        <b-form-group label="" label-for="event-name">
          <b-form-input
            id="event-name"
            v-model="currentData.event_name"
            autofocus
            trim
            :placeholder="lang('t_eventName')"
          />
        </b-form-group>

        <!-- Event Note -->

        <b-form-group label="" label-for="eventNote">
          <b-form-input
            id="eventNote"
            v-model="currentData.event_note"
            autofocus
            trim
            :placeholder="lang('t_eventNote')"
          />

          <template #option="{ color, label }">
            <div
              class="rounded-circle d-inline-block mr-50"
              :class="`bg-${color}`"
              style="height: 10px; width: 10px"
            />
            <span> {{ label }}</span>
          </template>

          <template #selected-option="{ color, label }">
            <div
              class="rounded-circle d-inline-block mr-50"
              :class="`bg-${color}`"
              style="height: 10px; width: 10px"
            />
            <span> {{ label }}</span>
          </template>
        </b-form-group>

        <!-- Number -->

        <b-form-group label="" label-for="number">
          <b-form-input
            id="number"
            v-model="currentData.event_contact_number"
            autofocus
            trim
            :placeholder="lang('t_number')"
          />

          <template #option="{ color, label }">
            <div
              class="rounded-circle d-inline-block mr-50"
              :class="`bg-${color}`"
              style="height: 10px; width: 10px"
            />
            <span> {{ label }}</span>
          </template>

          <template #selected-option="{ color, label }">
            <div
              class="rounded-circle d-inline-block mr-50"
              :class="`bg-${color}`"
              style="height: 10px; width: 10px"
            />
            <span> {{ label }}</span>
          </template>
        </b-form-group>

        <!-- Date & Time -->

        <b-form-group label="" label-for="end-date">
          <flat-pickr
            v-model="currentData.event_start_time"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d H:i',
              time_24hr: true,
              enableTime: true,
              allowInput: true,
            }"
            :placeholder="lang('t_eventDate')"
          />
        </b-form-group>
        <b-form-group :label="lang('t_color')" label-for="event-color">
          <b-row align-h="center">
            <b-button
              class="mr-1"
              style="height: 5vh"
              size="lg"
              v-for="(item, i) in cal_colors"
              :key="i"
              @click="currentData.event_color = item.color"
              :variant="item.color"
            >
              <feather-icon
                size="18"
                v-if="currentData.event_color == item.color"
                icon="CheckIcon"
              ></feather-icon>
            </b-button>
          </b-row>
        </b-form-group>
      </b-modal>
      <b-modal
        @show="getCustomerDetails"
        no-close-on-esc
        no-close-on-backdrop
        centered
        :title="`${event.title} - ${new Date(
          new Date(event.start || 0).getTime() + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, '$1 $2')}`"
        v-model="isEventDedailtsModalActive"
        max-width="540"
        :ok-title="`${lang('t_call')}: ${event.extendedProps.contact_number}`"
        :cancel-title="lang('t_displayOnCRM')"
        @ok="start_call(event.extendedProps.contact_number)"
        @cancel="displayOnCRM(event.extendedProps.contact_number)"
      >
        <span
          >{{ event.extendedProps.customer_no }} -
          {{ event.extendedProps.customer_name }}</span
        >
        <br />
        <span>{{ event.extendedProps.description }}</span>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    flatPickr,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BModal,
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  mounted: function () {
    console.log("refCalendar", this.$refs.refCalendar);
    this.calendarOptions.events = [];
    this.getEvents();
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      isEdit: false,
      currentData: {
        event_name: "",
        event_note: "",
        event_owner: "",
        event_contact_number: "",
        event_date: "",
        event_start_time: "",
        event_end_time: "",
        event_color: "primary",
      },
      cal_colors: [
        {
          color: "danger",
          label: "Personal",
        },
        {
          color: "primary",
          label: "Business",
        },
        {
          color: "warning",
          label: "Family",
        },
        {
          color: "success",
          label: "Holiday",
        },
        {
          color: "info",
          label: "ETC",
        },
      ],
      records: [
        {
          title: "test",
          start: "2022-04-21 14:00",
          end: "2022-04-21 14:00",
          allDay: false,
          url: "",
          extendedProps: {
            calendar: "",
            guests: [],
            location: "locaton",
            description: "desc",
          },
        },
      ],
    };
  },
  methods: {
    async displayOnCRM(phone) {
      console.log(phone);
      await this.$router.replace('/crm');

      globalThis.v_navbar.selected_button = 'crm';
      setTimeout(() => {
        if (globalThis.crm) {
          globalThis.crm.getCustomerByPhone(phone.slice(-10));
        }
      }, 1000);


    },
    async getCustomerDetails() {
      var response = (
        await this.$http_in.get(
          `/crm/v1/CustomersByPhone/${this.event.extendedProps.contact_number.substr(
            -10
          )}`
        )
      ).data;

      this.event.extendedProps.customer_no =
        response.result.length > 0
          ? response.result[0].hasOwnProperty("Adı Soyadı")
            ? response.result[0]["Adı Soyadı"]
            : ""
          : "";
      this.event.extendedProps.customer_name =
        response.result.length > 0
          ? response.result[0].hasOwnProperty("Müşteri No")
            ? response.result[0]["Müşteri No"]
            : ""
          : "";
    },
    start_call(phone) {
      globalThis.LayoutContentRendererDefault.preview_call_request(phone);
    },
    getEvents: async function () {
      var response = (await this.$http_in.get(`/agent/v1/Calendar`)).data;
      this.calendarOptions.events = [];
      // const merge_events = [].concat(response[0], response[1]);
      const merge_events = response;
      for (const item of merge_events) {
        console.log(
          "this.cal_colors.find(e => e.color == item.event_color)",
          this.cal_colors.find((e) => e.color == item.color) == undefined
            ? "Personel"
            : this.cal_colors.find((e) => e.color == item.color).label
        );
        this.calendarOptions.events.push({
          id: item._id,
          title: item.finishcode,
          start: item.start,
          end: item.end,
          extendedProps: {
            calendar:
              this.cal_colors.find((e) => e.color == item.color) == undefined
                ? "Personel"
                : this.cal_colors.find((e) => e.color == item.color).label,
            guests: "",
            location: "",
            description: item.note,
            contact_number: item.contact_number,
          },
          allDay: false,
        });
      }
      // this.events = merge_events;
    },

    check_data() {
      if (
        this.currentData.event_name.trim() == "" ||
        this.currentData.event_note.trim() == "" ||
        this.currentData.event_contact_number.trim() == "" ||
        this.currentData.event_start_time.trim() == ""
      ) {
        return true;
      }
      return false;
    },

    async add_record() {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      var response;
      response = (
        await this.$http_in.post(`/agent/v1/Calendar`, {
          EventInfo: JSON.stringify(this.currentData),
        })
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.getEvents();
      globalThis.LayoutContentRendererDefault.getEvents();
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDedailtsModalActive,
    } = useCalendar();

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDedailtsModalActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
